import React from 'react'
import IllustrationCard from './card'
import { navigate } from "gatsby"

const SearchImage = class extends React.Component {
  constructor(props) {
    super(props)
    const ids = this.searchList(props.tag)
    this.state = {
      showList: ids,
      tag: props.tag
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.tag !== prevProps.tag) {
      this.updateList(this.props.tag)
    }
  }

  searchByTag = (tag, list) => {
    if (tag === "") { return list }
    return list.filter(illustration => {
      return illustration.frontmatter.tags.some((t) => t.indexOf(tag) !== -1)
    })
  }

  searchList = (tag) => {
    let list = this.props.illustrations.map(({ node: illustration }) => { return illustration } )
    list = this.searchByTag(tag, list)

    return list.map((illustration) => illustration.id)
  }

  updateList = (tag) => {
    this.setState(
      {
        tag: tag
      },
      () => {
        const list = this.searchList(tag)
        this.setState({ showList: list })
        navigate(`/illustrations/?tag=${tag}`)
      }
    )
  }

  changeSearchSelect = (event) => {
    this.updateList(event.target.value)
  }

  render() {
    const illustrations = this.props.illustrations.filter(({node: illustration}) => {
      return this.state.showList.includes(illustration.id);
    })
    return (
      <section id="illustrations" className="hero is-small pt-4">
        <h1 className="title is-size-4">イラスト一覧</h1>
        <div className="hero-head px-1">
          <div className="select mb-4 is-info is-size-7-mobile">
            <select value={this.state.tag} onChange={this.changeSearchSelect}>
              <option value={""}>タグを選択</option>
              {this.props.tags && this.props.tags.map(({ node: tag }) => (
                <option value={tag.frontmatter.title} key={tag.id}>{tag.frontmatter.title}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="hero-body px-1">
          <div className="columns is-mobile is-multiline is-centered is-variable is-2">
            {illustrations && illustrations.map(({ node: illustration }) => (
              <div className="column is-3-tablet is-3-desktop is-6-mobile" key={illustration.id} sid={illustration.id}>
                <IllustrationCard illustration={illustration} tag={this.state.tag}/>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default SearchImage
