import { useStaticQuery, graphql } from "gatsby"

export const useAllTags = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query AllTags {
        allMarkdownRemark(
          filter: {frontmatter: {templateKey: {eq: "tag"}}}
          sort: {order: ASC, fields: frontmatter___s}
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }        
            }
          }
        }
      }
    `
  )
  return allMarkdownRemark
}
