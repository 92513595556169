import React from 'react'
import Layout from '../../components/layout'
import BaseSEO from '../../components/seo'
import ShowImagesWithSearch from '../../components/illustrations/show-images-with-search'
import { useAllIllustrations } from "../../hooks/use-all-illustrations"
import { useAllTags } from "../../hooks/use-all-tags"

const IngexPage = ({ location }) => {
  const { edges: illustrations } = useAllIllustrations()
  const { edges: tags } = useAllTags()
  let tag = ""
  if (location.search) {
    let tagParam = location.search.replace(/^\?/, "").split("&").find((param) => { return param.match(/tag=/) })
    if (tagParam) tag = decodeURI(tagParam.split("tag=")[1])
  }

  return (
    <Layout isRoot={false} isWebsite={true} noIndex={false} location={location}>
      <BaseSEO title="るるふのイラスト全集" description="イラストの一覧を閲覧できます。タグから検索することも可能です。" />
      <ShowImagesWithSearch illustrations={illustrations} tags={tags} tag={tag}/>
    </Layout>
  )
}

export default IngexPage
